import { getImageSrcSet } from '@/components/shared/Image';
import { Flex, FlexProps, Heading, Link } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import NextLink from 'next/link';
import { rem } from 'polished';

export const Partners = (flexProps: FlexProps) => {
  const { t } = useTranslation('home');

  return (
    <Flex flexDir="column" alignItems="center" gap="space.20" px="space.24" {...flexProps}>
      <Heading
        as="h3"
        fontSize={{ base: 'sm', md: 'md' }}
        letterSpacing="0.3em"
        fontWeight="medium"
        textTransform="uppercase"
        color="gray.5"
      >
        {t('partners.title')}
      </Heading>
      <Flex
        columnGap="space.40"
        rowGap="space.20"
        wrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        <Link
          as={NextLink}
          href="https://polygon.technology/"
          target="_blank"
          rel="noopener noreferrer"
          w={{ base: rem(140), md: rem(188) }}
          h={{ base: rem(40), md: rem(52) }}
        >
          <Image
            src="/images/home/polygon.png"
            alt="Polygon logo"
            width={187}
            height={52}
            sizes={getImageSrcSet({
              base: '140px',
              md: '188px',
            })}
            unoptimized
          />
        </Link>
        <Link
          as={NextLink}
          href="https://www.immutable.com/"
          target="_blank"
          rel="noopener noreferrer"
          w={{ base: rem(156), md: rem(208) }}
          h={{ base: rem(40), md: rem(52) }}
        >
          <Image
            src="/images/home/immutable.png"
            alt="Immutable logo"
            width={208}
            height={52}
            sizes={getImageSrcSet({
              base: '156px',
              md: '208px',
            })}
            unoptimized
          />
        </Link>
        <Link
          as={NextLink}
          href="https://onbeam.com/"
          target="_blank"
          rel="noopener noreferrer"
          w={{ base: rem(116), md: rem(155) }}
          h={{ base: rem(40), md: rem(52) }}
        >
          <Image
            src="/images/home/beam.png"
            alt="Beam logo"
            width={155}
            height={52}
            sizes={getImageSrcSet({
              base: '116px',
              md: '155px',
            })}
            unoptimized
          />
        </Link>
        <Link
          as={NextLink}
          href="https://meritcircle.io/"
          target="_blank"
          rel="noopener noreferrer"
          w={{ base: rem(128), md: rem(170) }}
          h={{ base: rem(40), md: rem(52) }}
        >
          <Image
            src="/images/home/merit-circle.png"
            alt="Merit Circle logo"
            width={170}
            height={52}
            sizes={getImageSrcSet({
              base: '128px',
              md: '170px',
            })}
            unoptimized
          />
        </Link>
        <Link
          as={NextLink}
          href="https://www.avax.network/"
          target="_blank"
          w={{ base: rem(180), md: rem(235) }}
          h={{ base: rem(35), md: rem(48) }}
        >
          <Image
            src="/images/home/avalance.webp"
            alt="Avalanche logo"
            width={235}
            height={48}
            sizes={getImageSrcSet({
              base: '180px',
              md: '235px',
            })}
            unoptimized
          />
        </Link>
      </Flex>
    </Flex>
  );
};
