import Mint from '@/components/buttons/Mint';
import { FormatCryptoCurrency } from '@/components/primitives';
import { Image, getImageSrcSet } from '@/components/shared/Image';
import { Price } from '@/lib/reservoir/reservoir.generated';
import { formatCollectionName } from '@/utils/addresses';
import { repeatArrayToLength } from '@/utils/repeatArray';
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Text,
  keyframes,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Card, MotionBox, type CardProps } from '@sphere/ui';
import { AnimatePresence } from 'framer-motion';
import { nanoid } from 'nanoid';
import useTranslation from 'next-translate/useTranslation';
import NextLink, { type LinkProps } from 'next/link';
import { rem } from 'polished';
import { useEffect, useRef, useState } from 'react';

type MintingCardProps = CardProps & {
  link: Omit<LinkProps, 'as' | 'passHref'>;
  collectionId?: string;
  title?: string;
  fallbackImage: string;
  isActive?: boolean;
  price?: Price;
  sampleImages?: string[];
};

const borderColors = ['#FFFFFF', '#D0D0D0', '#898989', '#4E4E4E'];

const MIN_STACK_LENGTH = 4;

const pulseAnimation = keyframes({
  '0%': {
    boxShadow: '0 0 0 0px rgba(235, 188, 255, 0.8)',
  },
  '100%': {
    boxShadow: '0 0 0 10px rgba(235, 188, 255, 0)',
  },
});

export const MintingCard = ({
  link,
  collectionId,
  title,
  fallbackImage,
  isActive,
  price,
  sampleImages,
  ...props
}: MintingCardProps) => {
  const { t } = useTranslation('common');
  const cardOffset = useBreakpointValue({ base: 20, sm: 27 });
  const interval = useRef<NodeJS.Timeout | null>(null);
  const [stack, setStack] = useState(
    (sampleImages?.length
      ? repeatArrayToLength(sampleImages, MIN_STACK_LENGTH)
      : new Array(MIN_STACK_LENGTH).fill(fallbackImage)
    ).map((src: string) => ({
      src,
      id: nanoid(),
    })),
  );

  useEffect(() => {
    interval.current = setInterval(() => {
      setStack(currentStack => {
        const newStack = [...currentStack];
        const newLast = newStack.shift()!;
        return [...newStack, newLast];
      });
    }, Math.random() * 2000 + 1500);

    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, [stack]);

  const subset = stack.slice(-1 * stack.length);

  return (
    <NextLink {...link}>
      <Card
        {...props}
        data-testid="minting-card"
        h="full"
        role="group"
        isActive={isActive}
        bg="gray.2"
        transition="all .2s ease"
        borderColor="gray.4"
        overflow="hidden"
        px="space.32"
        py="space.24"
        _hover={{ borderColor: 'gray.5' }}
      >
        {title && (
          <Text fontSize="xl" fontWeight="medium" noOfLines={1}>
            {formatCollectionName(title)}
          </Text>
        )}
        {price?.amount &&
          ((price.amount.decimal || 0) > 0 ? (
            <Flex align="center" gap="space.2">
              <FormatCryptoCurrency
                amount={price.amount.decimal}
                address={price.currency?.contract}
                decimals={price.currency?.decimals}
                logoHeight={14}
                fontWeight="medium"
                fontSize="md"
              />
            </Flex>
          ) : (
            <Text>{t('mint.free')}</Text>
          ))}
        <AspectRatio position="relative" width="100%" ratio={1} mt="space.32">
          <AnimatePresence>
            {subset.map((card, index) => (
              <MotionBox
                key={card.id}
                position="absolute"
                borderRadius="radius.28"
                overflow="hidden"
                animate={{
                  y: index * (cardOffset || 27),
                  scale: 1 - index * 0.1,
                  zIndex: stack.length - index,
                  borderColor: borderColors[index],
                  borderWidth: index === 0 ? 10 : 15,
                  opacity: index === stack.length - 1 ? [1, 0, 1] : 1,
                }}
                transition={{
                  duration: 0.75,
                  ease: 'easeInOut',
                  zIndex: { delay: 0.75 },
                  opacity: { delay: 0.25, duration: 0.75, times: [0, 0.9, 1] },
                  scale: index === stack.length - 1 ? { delay: 0.75 } : undefined,
                }}
                layout="size"
              >
                <Image
                  src={card.src}
                  alt=""
                  fill
                  objectFit="cover"
                  sizes={getImageSrcSet({ base: '95vw', md: '30vw', '2xl': '22vw' })}
                />
              </MotionBox>
            ))}
          </AnimatePresence>
        </AspectRatio>
        <Flex justify="space-between" align="center" mt="space.64">
          <Flex align="center" gap="space.12">
            <Box
              boxSize={rem(8)}
              bg="primaryLight"
              rounded="full"
              animation={`${pulseAnimation} 1.6s infinite`}
            />
            <Text fontSize="sm" textTransform="uppercase">
              {t('mint.minting-now')}
            </Text>
          </Flex>
          <Box onClick={e => [e.stopPropagation(), e.preventDefault()]}>
            <Mint
              collectionId={collectionId}
              trigger={
                <Button variant="primary" zIndex={4}>
                  {t('mint.button.label')}
                </Button>
              }
            />
          </Box>
        </Flex>
      </Card>
    </NextLink>
  );
};
