import { isDefined } from '@/types/type-presence';
import { compareAddresses } from '@/utils/addresses';
import { HighlightType, HighlightedCollection } from '@prisma/client';
import { Address, checksumAddress } from 'viem';
import { CollectionType, useCollections } from './use-collections';

type HomepageData = {
  hot?: CollectionType;
  minting: CollectionType[];
  popular: CollectionType[];
};

export const filterHighlightedData = (data: HighlightedCollection[]) => {
  const hotData = data?.filter(item => item.types.includes(HighlightType.HOT))[0];
  // Retrieve all and later check for top 4 that are actually minting according to Reservoir
  const mintingData = data?.filter(item => item.types.includes(HighlightType.MINTING));
  // Only retrieve top 4
  const popularData = data?.filter(item => item.types.includes(HighlightType.POPULAR)).slice(0, 4);

  return { hotData, mintingData, popularData };
};

const useHomepageData = (data: HighlightedCollection[]): HomepageData => {
  const { hotData, mintingData, popularData } = filterHighlightedData(data);

  const { data: collectionsResponse } = useCollections(
    {
      contract: [
        hotData?.address,
        ...(mintingData ?? []).map(d => d.address),
        ...(popularData ?? []).map(d => d.address),
      ].filter(isDefined),
    },
    !!data,
  );

  const collectionsData = collectionsResponse?.collections?.map(collection => ({
    ...collection,
    id: checksumAddress(collection.id as Address),
  }));

  if (!data || !collectionsData) {
    return {
      hot: undefined,
      minting: [],
      popular: [],
    };
  }

  const hotCollection = collectionsData.find(c => compareAddresses(c.id, hotData?.address));
  const popularCollections = collectionsData
    .filter(c => popularData?.map(d => checksumAddress(d.address as Address)).includes(c.id ?? ''))
    .filter(isDefined);
  const mintingCollections = collectionsData
    ?.filter(c => mintingData?.map(d => checksumAddress(d.address as Address)).includes(c.id ?? ''))
    // Check whether the collection is actually minting according to Reservoir
    .map(collection => {
      const mintData = collection?.mintStages?.find(stage => stage.kind === 'public');
      if (!mintData) return undefined;
      return collection;
    })
    .filter(isDefined)
    .slice(0, 4);

  return {
    hot: hotCollection,
    popular: popularCollections ?? [],
    minting: mintingCollections ?? [],
  };
};

export default useHomepageData;
