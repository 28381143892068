import { FormatCryptoCurrency } from '@/components/primitives';
import { CollectionCard } from '@/components/shared/CollectionCard';
import { CollectionType } from '@/hooks/use-collections';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { getSupportedChainById } from '@/utils/getChain';
import { formatNumber } from '@/utils/numbers';
import optimizeImage from '@/utils/optimizeImage';
import { routes } from '@/utils/routes';
import { Box, Container, Flex, Text, useBreakpointValue, type BoxProps } from '@chakra-ui/react';
import ArrowRight from '@sphere/icons/components/ArrowRight';
import { ButtonLink, CardCarousel } from '@sphere/ui';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { useState } from 'react';

type Props = BoxProps & {
  collections: CollectionType[];
  hideNavigation?: boolean;
  title?: string;
};

export const PopularCollections = ({
  collections,
  hideNavigation = false,
  title,
  ...boxProps
}: Props) => {
  const marketplaceChain = useMarketplaceChain();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation('home');

  return (
    <Box {...boxProps}>
      <CardCarousel
        title={title || t('popular-collections.title')}
        hideNavigation={hideNavigation}
        visibleItems={{ base: 1.15, md: 2, lg: 3, '2xl': 4 }}
        scrollAmount={{ base: 1, md: 2, lg: 3, '2xl': 4 }}
        onIndexChange={index => setActiveIndex(index)}
      >
        {collections.map((collection, index) => (
          <CollectionCard
            key={`popular-collection-${index}`}
            image={{
              src: optimizeImage(
                collection.banner || collection.sampleImages?.[0] || collection.image,
                800,
              ),
              alt: collection?.name,
            }}
            link={{
              href: routes.collections.detail(
                collection.id ?? '',
                (getSupportedChainById(collection.chainId) ?? marketplaceChain).routePrefix,
              ),
            }}
            creator={{
              avatar: optimizeImage(collection?.image, 72 * 2),
              address: collection.creator,
            }}
            title={collection?.name}
            description={collection?.description}
            isActive={isMobile && index === activeIndex}
            h="full"
          >
            <Flex gap="space.18" alignItems="flex-end" flexGrow={1} mt="space.12">
              {!!collection.tokenCount && (
                <Flex direction="column" gap="space.2">
                  <Text color="gray.6" fontSize="xs">
                    {t('popular-collections.collection-card.items')}
                  </Text>
                  <Text color="gray.1" fontWeight="medium" fontSize="sm">
                    {formatNumber(collection.tokenCount, 1, true)}
                  </Text>
                </Flex>
              )}
              {!!collection.ownerCount && (
                <Flex direction="column" gap="space.2">
                  <Text color="gray.6" fontSize="xs">
                    {t('popular-collections.collection-card.owners')}
                  </Text>
                  <Text color="gray.1" fontWeight="medium" fontSize="sm">
                    {formatNumber(collection.ownerCount, 1, true)}
                  </Text>
                </Flex>
              )}
              {!!collection.floorAsk?.price?.amount?.decimal && (
                <Flex direction="column" gap="space.2">
                  <Text color="gray.6" fontSize="xs">
                    {t('popular-collections.collection-card.floor-price')}
                  </Text>
                  <FormatCryptoCurrency
                    amount={collection.floorAsk.price.amount.decimal}
                    address={collection.floorAsk.price.currency?.contract}
                    decimals={collection.floorAsk.price.currency?.decimals}
                    maximumFractionDigits={2}
                    logoHeight={14}
                    fontWeight="medium"
                    fontSize="sm"
                    color="gray.1"
                  />
                </Flex>
              )}
              {!!collection.volume?.allTime && (
                <Flex direction="column" gap="space.2">
                  <Text color="gray.6" fontSize="xs">
                    {t('popular-collections.collection-card.volume')}
                  </Text>
                  <FormatCryptoCurrency
                    amount={collection.volume.allTime}
                    maximumFractionDigits={2}
                    logoHeight={14}
                    fontWeight="medium"
                    fontSize="sm"
                    color="gray.1"
                  />
                </Flex>
              )}
            </Flex>
          </CollectionCard>
        ))}
      </CardCarousel>
      {!hideNavigation && (
        <Container display="flex" justifyContent="center" mt="space.32">
          <ButtonLink
            href={routes.collections.overview(marketplaceChain.routePrefix)}
            variant="secondary"
            rightIcon={<ArrowRight boxSize={rem(15)} />}
          >
            {t('popular-collections.view-all')}
          </ButtonLink>
        </Container>
      )}
    </Box>
  );
};
