import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { routes } from '@/utils/routes';
import { AspectRatio, Box, Text, VStack, chakra } from '@chakra-ui/react';
import ArrowRight from '@sphere/icons/components/ArrowRight';
import { ButtonLink, useFluidSize } from '@sphere/ui';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';

export const Intro = () => {
  const { t } = useTranslation('home');
  const marketplaceChain = useMarketplaceChain();
  const titleFontSize = useFluidSize('2xl', '5xl', 'sm');

  return (
    <VStack my={{ base: 'space.48', md: 'space.128' }}>
      <Text
        as="h1"
        fontSize={titleFontSize}
        fontWeight="semibold"
        lineHeight={1.1}
        letterSpacing="-0.02em"
        textAlign="center"
        maxWidth={{ base: rem(320), sm: '5xl' }}
      >
        <Trans i18nKey="home:intro.title" components={{ br: <br /> }} />
      </Text>
      <ButtonLink
        href={routes.collections.overview(marketplaceChain.routePrefix)}
        mt="space.12"
        variant="secondary"
        rightIcon={<ArrowRight boxSize={rem(15)} />}
      >
        {t('intro.button.label')}
      </ButtonLink>
      <Box w="100%" mt={{ base: 'space.64', md: rem(160) }}>
        <AspectRatio ratio={{ base: 4 / 3, md: 21 / 9 }}>
          <chakra.video autoPlay loop muted playsInline objectFit="cover">
            <source src="/beam-imx-poly.mp4" type="video/mp4" />
          </chakra.video>
        </AspectRatio>
      </Box>
    </VStack>
  );
};
