import { Image, getImageSrcSet } from '@/components/shared/Image';
import { MarkdownRenderer } from '@/components/shared/MarkdownRenderer';
import { useENSResolver } from '@/hooks/useENSResolver';
import { formatCollectionName } from '@/utils/addresses';
import { AspectRatio, Box, Flex, LinkOverlay, Text } from '@chakra-ui/react';
import { Card, type CardProps } from '@sphere/ui';
import useTranslation from 'next-translate/useTranslation';
import NextLink, { type LinkProps } from 'next/link';
import { rem } from 'polished';
import { type ReactNode } from 'react';

type CollectionCardProps = Omit<CardProps, 'title'> & {
  link: Omit<LinkProps, 'as' | 'passHref'>;
  title?: string | null;
  description?: string | null;
  image?: {
    src?: string;
    alt?: string | null;
  } | null;
  creator?: {
    address?: string;
    avatar?: string;
  };
  children?: ReactNode;
  isActive?: boolean;
  isDraft?: boolean;
};

export const CollectionCard = ({
  link,
  title,
  description,
  image,
  creator,
  children,
  isActive,
  isDraft,
  ...props
}: CollectionCardProps) => {
  const { t } = useTranslation('common');
  const { displayName } = useENSResolver(creator?.address);

  return (
    <Card
      {...props}
      data-testid="collection-card"
      role="group"
      isActive={isActive}
      bg="gray.2"
      transition="all .2s ease"
      borderColor="gray.4"
      overflow="hidden"
      pb={0}
      _hover={{ borderColor: 'gray.5' }}
    >
      <Box position="relative">
        <Box borderTopRadius="radius.28" overflow="hidden">
          <AspectRatio
            ratio={1.4}
            borderRadius="radius.8"
            transition={'transform .2s'}
            _groupHover={{
              transform: 'scale(115%)',
            }}
          >
            <Image
              src={image?.src}
              alt={image?.alt || ''}
              fill
              objectFit="cover"
              sizes={getImageSrcSet({ base: '95vw', md: '30vw', '2xl': '22vw' })}
            />
          </AspectRatio>
        </Box>

        {creator && (
          <Image
            src={creator.avatar}
            alt={creator.address || ''}
            width={56}
            height={56}
            boxSize={rem(56)}
            pos="absolute"
            bottom="space.-28"
            right="space.8"
            objectFit="cover"
            bg="black"
            rounded="full"
            border="1px solid"
            borderColor="gray.4"
            zIndex={1}
          />
        )}
      </Box>

      <Box pos="relative" h="full">
        {image && (
          <Image
            pos="absolute"
            top={0}
            height={200}
            width={500}
            h="100%"
            w="100%"
            src={image.src}
            alt={image.alt || ''}
            objectFit="cover"
            opacity={0.5}
          />
        )}
        <Flex
          background="linear-gradient(#22222200 0%, #222222 60%)"
          backdropFilter="blur(80px)"
          pos="relative"
          h="full"
          flexDir="column"
          textAlign="left"
          p="space.16"
        >
          <Box mr="space.48">
            {isDraft && (
              <Box
                bg="blackBg"
                color="white"
                fontSize="2xs"
                fontWeight="medium"
                px="space.4"
                py="space.2"
                rounded="radius.2"
                width="fit-content"
                mb="space.2"
              >
                {t('components.collection-card.draft')}
              </Box>
            )}
            <NextLink {...link} passHref legacyBehavior>
              <LinkOverlay
                _before={{
                  right: 'calc(var(--chakra-space-space-16) * -1)',
                  height: '1000px',
                  transform: 'translateY(-50%)',
                }}
              >
                {title && (
                  <Text fontSize="lg" fontWeight="medium" noOfLines={2} lineHeight="md">
                    {formatCollectionName(title)}
                  </Text>
                )}
              </LinkOverlay>
            </NextLink>
          </Box>

          {displayName && (
            <Text
              color="gray.6"
              fontSize="sm"
              noOfLines={1}
              mr="space.4"
              mt={{ base: 0, md: 'space.2' }}
            >
              {displayName}
            </Text>
          )}

          {description && (
            <MarkdownRenderer
              fontSize="md"
              color="gray.6"
              noOfLines={3}
              mt={{ base: 'space.10', md: 'space.12' }}
              disableLinks
            >
              {description}
            </MarkdownRenderer>
          )}

          {children && (
            <Flex justifyContent="flex-end" mt="auto" pt={{ base: 'space.10', md: 'space.12' }}>
              {children}
            </Flex>
          )}
        </Flex>
      </Box>
    </Card>
  );
};
